import Keycloak from "keycloak-js";
import { AUTH_URL, AUTH_CLIENT_ID, AUTH_REALM } from "./Constants/Api_URL";

const keycloak = new Keycloak({
    url: AUTH_URL,
    realm: AUTH_REALM,
    clientId: AUTH_CLIENT_ID,
});

export default keycloak;
