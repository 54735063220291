export const BASE_URL = 'https://mobibus-gateway.ndrive.com';

export const BUS = BASE_URL + '/artifacts-manager/bus';
export const VERSION = BASE_URL + '/artifacts-manager/version';
export const SET_BETA_BUS = BASE_URL + '/artifacts-manager/betaBus/';
export const GET_BUS = BASE_URL + '/busLocation/getAll';

export const AUTH_URL = 'https://mobibus-auth.ndrive.com';
export const AUTH_REALM = 'master';
export const AUTH_CLIENT_ID = 'mobibus-artifacts-manager';
