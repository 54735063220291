import React from 'react';
import { FaCircle, FaRegCircle } from 'react-icons/fa';



class Status extends React.Component {

    status() {
        const bus = this.props.buses.find(elem => elem.busId === this.props.busId);
        const now = new Date();
        const actualTime = Math.round(now.getTime() / 1000);
        if (bus) {
            return actualTime - bus.time < 60 ?
                <div><FaCircle style={{ color: 'green' }} /> <span style={{ verticalAlign: 'middle' }} >Online</span></div> :
                <div><FaCircle style={{ color: 'orange' }} /> <span style={{ verticalAlign: 'middle' }} >Idle</span></div>
        } else {
            return <div><FaRegCircle /> <span style={{ verticalAlign: 'middle' }} >Offline</span></div>
        }
    }

    render() {
        return (
            this.status()
        );
    }
}

export default Status;
