import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, FormText, Alert, Spinner } from 'reactstrap';
import axios from 'axios';
import { VERSION } from '../../Constants/Api_URL';
import { withKeycloak } from '@react-keycloak/web';

class ModalAddArtifact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: '',
            number: '',
            file: '',
            isBeta: false,
            error: '',
            loading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleOnUploadFile = this.handleOnUploadFile.bind(this);
    }

    toggle() {
        this.props.toggle();
        this.setState({
            description: '',
            number: '',
            file: '',
            loading: false,
            error: ''
        })
    }

    isDisable() {
        return this.state.loading || !this.state.number || !this.state.file;
    }

    handleChange() {
        this.setState(prevState => ({
            isBeta: !prevState.isBeta
        }));
    }

    handleInput(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleOnUploadFile(event) {
        this.setState({
            file: event.target.files[0]
        });
    }

    onSubmit() {
        this.setState({ loading: true });

        let formData = new FormData();
        formData.append('number', this.state.number);
        formData.append('description', this.state.description);
        if (this.state.isBeta) {
            formData.append('isBeta', true);
        }
        formData.append('file', this.state.file);

        axios.post(VERSION, formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + this.props.keycloak.token,
                }
            }
        )
            .then(response => {
                this.setState({
                    loading: false
                });
                const data = {
                    id: response.data.id,
                    number: this.state.number,
                    description: this.state.description,
                    is_beta: this.state.isBeta,
                    published_at: response.data.published_at
                }
                this.props.insertRow(data);
                this.toggle();
            })
            .catch(error => {
                this.setState({
                    error: error.response.data,
                    loading: false
                })
            })

    }

    render() {
        return (
            <Modal centered={true} isOpen={this.props.isOpen} toggle={this.toggle} unmountOnClose={true}>
                <ModalHeader toggle={this.toggle}>Add new artifact</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="number">Version number</Label>
                            <Input type="text" name="number" id="number" placeholder="" value={this.state.number} onChange={event => this.handleInput(event)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input type="textarea" name="description" id="description" value={this.state.description} onChange={event => this.handleInput(event)} />
                        </FormGroup>
                        <FormGroup>
                            <FormText>Select whether the artifact is a beta version</FormText>
                            <Input className="ml-2" name="isBeta" type="checkbox" id="exampleCustomCheckbox" label="Beta" onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Input type="file" name="artifact" id="file" onChange={event => this.handleOnUploadFile(event)} />
                        </FormGroup>
                    </Form>
                    <div className="text-center">
                        {this.state.loading && <Spinner color="primary" />}
                    </div>
                    {this.state.error &&
                        <Alert color="danger" className="text-center mt-3">
                            {this.state.error}
                        </Alert>}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={this.toggle}>Cancel</Button>{' '}
                    <Button color="success" disabled={this.isDisable()} onClick={this.onSubmit}>Submit</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withKeycloak(ModalAddArtifact);
