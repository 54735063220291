import React from 'react';
import { Table, Spinner, Row, Form, FormGroup, Input, Col } from 'reactstrap';
import axios from 'axios';
import { FaTrashAlt, FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { IoIosAddCircleOutline } from "react-icons/io";
import ModalDelete from '../../Components/ModalDelete';
import { VERSION } from '../../Constants/Api_URL';
import ModalAddArtifact from '../../Components/ModalAddArtifact';
import moment from 'moment-timezone';
import './style.css';
import { withKeycloak } from '@react-keycloak/web';


class Version extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            input: '',
            addVersionModal: false,
            error: '',
            deleteModal: false,
            artifactId: '',
            artifactNumber: ''
        }

        this.toggleDelete = this.toggleDelete.bind(this);
        this.toggleAddVersion = this.toggleAddVersion.bind(this);
        this.insertRow = this.insertRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
    }

    componentDidMount() {
        axios.get(VERSION, { headers: { 'Authorization': 'Bearer ' + this.props.keycloak.token } })
            .then(response => {
                this.setState({
                    data: response.data,
                    loading: false
                })
            })
            .catch(error => {
                let responseError
                if (error.response) {
                    responseError = error.response.data
                } else if (error.request) {
                    responseError = error.request
                } else {
                    responseError = error.message
                }
                console.log(responseError)
                this.setState({
                    error: responseError,
                    loading: false
                })
            });
    }

    handleInput(event) {
        this.setState({
            input: event.target.value
        });
    }

    toggleAddVersion() {
        this.setState({
            addVersionModal: !this.state.addVersionModal
        });
    }

    toggleDelete() {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }

    initModal(artifactId, artifactNumber) {
        this.setState({
            artifactId: artifactId,
            artifactNumber: artifactNumber
        });
        this.toggleDelete();
    }

    getRows() {
        return this.searchVersion(this.state.data).map((row, index) =>
            <tr key={index}>
                <td>{row.number}</td>
                <td>{row.description}</td>
                <td>{row.is_beta ? <FaRegCheckCircle className="betaTrueArtifact" /> : <FaRegTimesCircle className="betaFalseArtifact" />}</td>
                <td>{moment(row.published_at).tz('Europe/Lisbon').format('LLL')}</td>
                <td className="trash" onClick={() => this.initModal(row.id, row.number)} ><FaTrashAlt /></td>
            </tr>
        );
    }

    searchVersion(data) {
        if (this.state.input === '') {
            return data;
        }
        const text = this.state.input.toUpperCase();
        return data.filter(data_row => data_row.number.toUpperCase().indexOf(text) !== -1);
    }

    insertRow(_data) {
        this.setState(prevState => ({
            data: [_data, ...prevState.data]
        }));
    }

    removeRow(versionId) {
        this.setState({
            data: this.state.data.filter(artifact => artifact.id !== versionId)
        })
    }

    render() {
        return (
            <div>
                <Row className="mt-4 mb-3">
                    <Col className="offset-lg-1">
                        <h1>Artifacts <IoIosAddCircleOutline className="addBus" onClick={this.toggleAddVersion} /></h1>
                    </Col>
                </Row>
                <Row >
                    <Form className="col-lg-3 offset-lg-1 mb-1">
                        <FormGroup>
                            <Input type="text" name="searchbar" id="searchbar" value={this.state.input} placeholder="Search artifact" onChange={event => this.handleInput(event)} />
                        </FormGroup>
                    </Form>
                </Row>
                <Row >
                    <Col className="col-lg-10 offset-lg-1">
                        <Table responsive striped hover bordered>
                            <thead >
                                <tr>
                                    <th>Version</th>
                                    <th>Description</th>
                                    <th>Beta</th>
                                    <th>Published Date</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.getRows()}
                            </tbody>
                        </Table>
                    </Col>
                    {this.state.loading &&
                        <Col className="col-lg-2 offset-lg-5">
                            <Spinner style={{ width: '4rem', height: '4rem' }} color="info" />
                        </Col>}
                </Row>
                <ModalAddArtifact toggle={this.toggleAddVersion} isOpen={this.state.addVersionModal} insertRow={this.insertRow} />
                <ModalDelete toggle={this.toggleDelete} isOpen={this.state.deleteModal} removeFunction={this.removeRow} object="artifact version" ident={this.state.artifactNumber} id={this.state.artifactId} url={VERSION} />
            </div>
        );
    }
}

export default withKeycloak(Version);
