import React, { Component } from 'react';
import * as routes from '../../Constants/routes';
import { NavLink } from 'react-router-dom';
import { FaSignOutAlt, FaBus } from 'react-icons/fa';
import { FiLayers } from 'react-icons/fi';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { withKeycloak } from '@react-keycloak/web';
class NavBar extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    logout() {
        this.props.keycloak.logout();
    }

    render() {

        return (
            <div>
                <Navbar color="light" light expand="md" className="fixed-top">
                    <NavbarBrand href="/">Artifact Management</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ms-auto" navbar>
                            <NavItem>
                                <NavLink to={routes.BUS} className="nav-link">
                                    <FaBus style={{ margin: '0 5px 0 0' }} />
                                    Buses
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to={routes.VERSION} className="nav-link">
                                    <span style={{ verticalAlign: 'middle' }}>
                                        <FiLayers style={{ margin: '0 5px 0 0' }} />Artifacts
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <button type="button" className="btn btn-light" onClick={() => this.logout()}>
                                    <FaSignOutAlt style={{ margin: '0 5px 0 0' }} />
                                    Sign out
                                </button>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}


export default withKeycloak(NavBar);
