import { useKeycloak } from "@react-keycloak/web";

const RestrictedRoute = ({ children }) => {
    const { keycloak, initialized } = useKeycloak();

    if (!initialized) {
        return <div>A carregar...</div>;
    }

    const isLoggedIn = keycloak.authenticated;
    const isAdmin = keycloak.hasRealmRole('admin');

    return isLoggedIn && isAdmin ? children : <p>Sem permissões de acesso</p>;
};

export default RestrictedRoute;
