import React from 'react';
import { Table, Spinner, Row, Form, FormGroup, Input, Col } from 'reactstrap';
import axios from 'axios';
import { FaTrashAlt, FaRegTimesCircle, FaRegCheckCircle } from 'react-icons/fa';
import { IoIosAddCircleOutline } from "react-icons/io";
import ModalBus from '../../Components/ModalAddBus';
import ModalDelete from '../../Components/ModalDelete';
import Status from '../../Components/Status';
import { BUS, SET_BETA_BUS, GET_BUS } from '../../Constants/Api_URL';
import moment from 'moment-timezone';
import './style.css';
import { withKeycloak } from '@react-keycloak/web';

const GET_BUS_TIME = 10000;
class Bus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            input: '',
            addBusModal: false,
            error: '',
            deleteModal: false,
            busToDelete: '',
            busStatus: []
        }

        this.toggleAddBusModal = this.toggleAddBusModal.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);
        this.insertRow = this.insertRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
    }

    componentDidMount() {
        axios.all([
            axios.get(BUS, { headers: { 'Authorization': 'Bearer ' + this.props.keycloak.token } }),
            axios.get(GET_BUS, { headers: { 'Authorization': 'Bearer ' + this.props.keycloak.token } })
        ])
            .then(response => {
                this.setState({
                    data: response[0].data,
                    busStatus: response[1].data,
                    loading: false
                });
                this.intervalId = setInterval(() => this.getAllBuses(), GET_BUS_TIME)
            })
            .catch(error =>
                this.setState({
                    error: error.response.data,
                    loading: false
                }));
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    getAllBuses() {
        axios.get(GET_BUS, { headers: { 'Authorization': 'Bearer ' + this.props.keycloak.token } })
            .then(response => {
                this.setState({
                    busStatus: response.data
                })
            })
            .catch(error => console.log(error))
    }

    toggleDelete() {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }

    initModal(busId) {
        this.setState({
            busToDelete: busId,
        });
        this.toggleDelete();
    }

    getRows() {
        return this.searchBus(this.state.data).sort((a, b) => (a.busid > b.busid) ? 1 : -1).map((row, index) =>
            <tr key={row.busid}>
                <td>{<Status buses={this.state.busStatus} busId={row.busid}></Status>}</td>
                <td>{row.busid}</td>
                <td>{row.number ? row.number : '---'}</td>
                <td className={row.is_beta ? 'betaTrue' : 'betaFalse'} onClick={() => this.changeBetaBus(row.busid, index)} >{row.is_beta ? <FaRegCheckCircle /> : <FaRegTimesCircle />}</td>
                <td>{moment(row.updated_date).tz('Europe/Lisbon').format('LLL')}</td>
                <td>{row.last_connected_time ? moment(row.last_connected_time).tz('Europe/Lisbon').format('LLL') : '---'}</td>
                <td className="trash" onClick={() => this.initModal(row.busid)} ><FaTrashAlt /></td>
            </tr>
        );
    }

    changeBetaBus(busId, index) {
        axios.put(SET_BETA_BUS + busId, null, { headers: { 'Authorization': 'Bearer ' + this.props.keycloak.token } })
            .then(response => {
                let newData = [...this.state.data];
                newData[index].is_beta = !newData[index].is_beta;
                this.setState({ data: newData });
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleInput(event) {
        this.setState({
            input: event.target.value
        });
    }

    toggleAddBusModal() {
        this.setState({
            addBusModal: !this.state.addBusModal,
        })
    }

    searchBus(data) {
        if (this.state.input === '') {
            return data;
        }
        const text = this.state.input.toUpperCase();
        return data.filter(data_row => data_row.busid.toUpperCase().indexOf(text) !== -1);
    }

    insertRow(data) {
        const insertData = { busid: data.busId, is_beta: data.isBeta, updated_date: data.updated_date }
        this.setState(prevState => ({
            data: [...prevState.data, insertData]
        }));
    }

    removeRow(busId) {
        this.setState({
            data: this.state.data.filter(bus => bus.busid !== busId)
        })
    }

    render() {
        return (
            <div>
                <Row className="mt-4 mb-3">
                    <Col className="offset-lg-1">
                        <h1>Buses <IoIosAddCircleOutline className="addBus" onClick={this.toggleAddBusModal} /></h1>
                    </Col>
                </Row>
                <Row >
                    <Form className="col-lg-3 offset-lg-1 mb-1">
                        <FormGroup>
                            <Input type="text" name="searchbar" id="searchbar" value={this.state.input} placeholder="Search Bus" onChange={event => this.handleInput(event)} />
                        </FormGroup>
                    </Form>
                </Row>
                <Row >
                    <Col className="col-lg-10 offset-lg-1">
                        <Table responsive striped hover bordered>
                            <thead >
                                <tr>
                                    <th>Status</th>
                                    <th>Bus id</th>
                                    <th>Version</th>
                                    <th>Beta</th>
                                    <th>Updated date</th>
                                    <th>Connected date</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.getRows()}
                            </tbody>
                        </Table>
                    </Col>
                    {this.state.loading &&
                        <Col className="col-lg-2 offset-lg-5">
                            <Spinner style={{ width: '4rem', height: '4rem' }} color="info" />
                        </Col>}
                </Row>
                <ModalBus insertRow={this.insertRow} toggle={this.toggleAddBusModal} isOpen={this.state.addBusModal} />
                <ModalDelete toggle={this.toggleDelete} isOpen={this.state.deleteModal} removeFunction={this.removeRow} object="bus" ident={this.state.busToDelete} id={this.state.busToDelete} url={BUS} />
            </div>
        );
    }
}

export default withKeycloak(Bus);
