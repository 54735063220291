import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Spinner, Row } from 'reactstrap';
import axios from 'axios';
import { withKeycloak } from '@react-keycloak/web';


class ModalDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            loading: false
        };
        this.removeBus = this.removeBus.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    removeBus() {
        this.setState({ loading: true });
        const url = this.props.url + `/${this.props.id}`;
        axios.delete(url, { headers: { 'Authorization': 'Bearer ' + this.props.keycloak.token } })
            .then(res => {
                this.setState({
                    loading: false
                });
                this.props.removeFunction(this.props.id);
                this.props.toggle();
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    error: error.response.data,
                    loading: false
                })
            });
    }

    toggle() {
        this.props.toggle();
        this.setState({
            error: '',
            loading: false
        });
    }


    render() {
        return (
            <Modal centered={true} isOpen={this.props.isOpen} toggle={this.toggle} >
                <ModalHeader toggle={this.toggle}>{`Remove ${this.props.object}`}</ModalHeader>
                <ModalBody>
                    <Row className="justify-content-center">
                        {`Are you sure you want to remove ${this.props.object} ${this.props.ident}?`}
                    </Row>
                    <Row className="justify-content-center mt-2">
                        {this.state.loading && <Spinner color="primary" />}
                    </Row>
                    {this.state.error &&
                        <Alert color="danger" className="text-center mt-3">
                            {this.state.error}
                        </Alert>}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" disabled={this.state.loading} onClick={this.toggle}>Cancel</Button>
                    <Button color="success" disabled={this.state.loading} onClick={this.removeBus}>Confirm</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withKeycloak(ModalDelete);
