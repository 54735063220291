import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, FormText, Alert, Spinner } from 'reactstrap';
import axios from 'axios';
import { BUS } from '../../Constants/Api_URL';
import { withKeycloak } from '@react-keycloak/web';

class ModalBus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            busId: '',
            isBeta: false,
            error: '',
            loading: false
        };

        this.addBus = this.addBus.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    addBus() {
        const data = {
            busId: this.state.busId,
            isBeta: this.state.isBeta,
        };

        this.setState({ loading: true });

        axios.post(BUS, data, { headers: { 'Authorization': 'Bearer ' + this.props.keycloak.token } })
            .then(res => {
                this.setState({
                    loading: false
                });
                data.updated_date = new Date().toISOString();
                this.props.insertRow(data);
                this.toggle();
            })
            .catch(error => {
                this.setState({
                    error: error.response.data,
                    loading: false
                })
            });
    }

    toggle() {
        this.props.toggle();
        this.setState({
            busId: '',
            isBeta: false,
            error: ''
        })
    }

    handleInput(event) {
        this.setState({
            busId: event.target.value
        });
    }

    handleChange() {
        this.setState(prevState => ({
            isBeta: !prevState.isBeta
        }));
    }

    isDisable() {
        return !this.state.busId || this.state.loading;
    }

    render() {
        return (
            <Modal centered={true} isOpen={this.props.isOpen} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>Add new bus</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="busId">Bus id</Label>
                            <Input type="text" name="busId" id="busId" placeholder="" value={this.state.busId} onChange={event => this.handleInput(event)} />
                        </FormGroup>
                        <FormGroup>
                            <FormText>Select whether the bus is a beta bus</FormText>
                            <Input className="ml-2" name="isBeta" type="checkbox" id="exampleCustomCheckbox" label="Beta" onChange={this.handleChange} />
                        </FormGroup>
                    </Form>
                    <div className="text-center">
                        {this.state.loading && <Spinner color="primary" />}
                    </div>
                    {this.state.error &&
                        <Alert color="danger" className="text-center mt-3">
                            {this.state.error}
                        </Alert>}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={this.toggle}>Cancel</Button>{' '}
                    <Button color="success" disabled={this.isDisable()} onClick={this.addBus}>Add bus</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withKeycloak(ModalBus);
