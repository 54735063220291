import React from 'react';
import {  Route, Routes, Navigate } from 'react-router-dom'
import ErrorPage from '../Scenes/ErrorPage';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "../Keycloak"
import RestrictedRoute from './RestrictedRoute';
import { BrowserRouter } from 'react-router-dom';
import NavBar from './NavBar';
import { Container } from 'reactstrap';
import * as routes from '../Constants/routes';
import Bus from '../Scenes/Bus';
import Version from '../Scenes/Version';

const App = () => {
    return (
        <div>
            <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required' }}>
                <BrowserRouter>
                    <NavBar />
                    <Container fluid style={{ paddingTop: '60px' }}>
                        <Routes>
                            <Route exact path={routes.BUS} element={<RestrictedRoute><Bus /></RestrictedRoute>} />
                            <Route exact path={routes.VERSION} element={<RestrictedRoute><Version /></RestrictedRoute>} />
                            <Route path='' element={<Navigate to={routes.BUS} />} />
                            <Route exact path='/error' element={<ErrorPage />} />
                        </Routes>
                    </Container>
                </BrowserRouter>
            </ReactKeycloakProvider>
        </div>
    );

}

export default App;
